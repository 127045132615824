import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_service-details-styles'
// components
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
// blocks
import Features from '@solid-ui-blocks/Features/Block06'
import Features2 from '@solid-ui-blocks/Features/Block07'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Team from '@solid-ui-blocks/Hero/Block03'
import Faq from '@solid-ui-blocks/Faq/Block02'

const ServiceDetails = props => {
    const { allBlockContent } = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props} allBlockContent={allBlockContent}>
            {/* TODO: Change SEO based on page route */}
            <Seo title='Services' />
            <Container variant='full' sx={styles.heroContainer}>
                <Features content={content['service-steps']} />
            </Container>
            <Divider space='5' />
            <Divider space='5' />
            <Features2 content={content['tech-stack']} />
            <Divider space='5' />
            <Divider space='5' />
            <Faq content={content['faq']} />
            <Divider space='5' />
            <Divider space='5' />
            <Blog content={content['case-studies']} />
            <Divider space='5' />
            <Divider space='5' />
            <Team content={content['consultation-footer']} />
        </Layout>
    )
}

export const query = graphql`
  query serviceDetailsBlockContent($page: String) {
    allBlockContent(
        filter: {page: {in: [$page, "shared"]}}
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServiceDetails
